import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ContentAPILeadTimeHoursStoreEffects } from './effects';
import { contentAPILeadTimeHoursReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'contentAPILeadTimeHours',
      contentAPILeadTimeHoursReducer,
    ),
    EffectsModule.forFeature([ContentAPILeadTimeHoursStoreEffects]),
  ],
})
export class ContentAPILeadTimeHoursStoreModule {}
