import { createAction, props } from '@ngrx/store';

export const loadRequest = createAction('[Content API Lead Time] Load Request');

export const loadSuccess = createAction(
  '[Content API Lead Time] Load Success',
  props<{ items: any[] }>(),
);

export const loadFailure = createAction(
  '[Content API Lead Time] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Content API Lead Time] Reset State');
