import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ContentAPIPoliciesStoreEffects } from './effects';
import { contentAPIPoliciesReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('contentAPIPolicies', contentAPIPoliciesReducer),
    EffectsModule.forFeature([ContentAPIPoliciesStoreEffects]),
  ],
})
export class ContentAPIPoliciesStoreModule {}
