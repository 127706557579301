import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ContentAPILeadTimeStoreEffects } from './effects';
import { contentAPILeadTimeReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('contentAPILeadTime', contentAPILeadTimeReducer),
    EffectsModule.forFeature([ContentAPILeadTimeStoreEffects]),
  ],
})
export class ContentAPILeadTimeStoreModule {}
